<template>
  <div class="popup-phone" v-show="dialogVisible">
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="beforClose"
      width="400px"
    >
      <template v-if="show">
        <div class="title">请输入微信绑定的手机号</div>
        <input
          type="text"
          maxlength="11"
          v-model="phone"
          oninput="value=value.replace(/[^0-9]/g,'')"
          class="phone-input"
          placeholder="请输入微信绑定的手机号"
        />
        <div class="flex bor">
          <input
            type="text"
            v-model="codeImg"
            maxlength="4"
            oninput="value=value.replace(/[^0-9A-Za-z]/g,'')"
            class="phone-input"
            placeholder="输入图形验证码"
          />
          <img
            :src="picImg"
            alt=""
            class="codeImgShow"
            @click.stop="getVerifyPic"
          />
        </div>
        <div class="flex conet top">
          <input
            class="flex-input"
            type="text"
            maxlength="6"
            v-model="code"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="输入验证码"
          />
          <div class="verification" @click.stop="verificationCodeIncident">
            {{ codeText }}
          </div>
        </div>
        <div
          class="dialog-btn flex conet j-c"
          @click="informationRegistrationIncident"
        >
          确认绑定
        </div>
      </template>
      <div v-else class="binding-success">
        <div class="bg"></div>
        <div class="text">已经绑定成功！</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { checkPhone } from '@/utils/verify';
import { countDown } from '@/utils/commonUtils';
import { verifyPic, checkVerify, personalCenterSms } from '@/api/personal';
import Utils from '@/utils';
export default {
  name: 'PopupPhone',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      phone: '',
      codeText: '发送验证码',
      getOnceCodeFlag: true,
      codeImg: '',
      codekey: '',
      picImg: '',
      getCodeFlag: false,
      code: ''
    };
  },
  watch: {
    codeImg(val) {
      if (val.length > 3) {
        checkVerify({
          key: this.codekey,
          code: val
        }).then((res) => {
          if (res.code == 200) {
            if (res.data) {
              this.getCodeFlag = true;
            } else {
              Utils.msgWarning('图形验证码校验失败');
              this.getVerifyPic();
              this.codeImg = '';
            }
          }
        });
      }
    }
  },
  created() {
    this.getVerifyPic();
  },
  methods: {
    beforClose() {
      this.$emit('beforClose', {});
    },
    informationRegistrationIncident() {
      if (!checkPhone(this.phone)) {
        Utils.msgWarning('手机号码不正确');
        return;
      }
      if (!this.getCodeFlag) {
        Utils.msgWarning('图形验证码不正确');
        return;
      }
      if (this.code == '') {
        Utils.msgWarning('验证码不能为空');
        return;
      }
      const obj = {
        phone: this.phone,
        code: this.code
      };
      this.$emit('informationRegistrationIncident', obj);
    },
    getVerifyPic() {
      verifyPic({}).then((res) => {
        if (res.code == 200) {
          this.picImg = 'data:image/png;base64,' + res.data.pic;
          this.codekey = res.data.key;
        }
      });
    },
    verificationCodeIncident() {
      const _this = this;
      if (!checkPhone(_this.phone)) {
        Utils.msgWarning('手机号码不正确');
        return;
      }
      if (!_this.getCodeFlag) {
        Utils.msgWarning('图形验证码不正确');
        return;
      }
      if (_this.getOnceCodeFlag) {
        _this.getOnceCodeFlag = false;
        personalCenterSms({
          phone: _this.phone
        }).then((res) => {
          if (res.code == 200) {
            Utils.msgSuccess('发送验证码成功，请注意查看手机短信');
          }
        });
        countDown(60, (time) => {
          _this.codeText = `${time}s`;
          if (time <= 0) {
            _this.getOnceCodeFlag = true;
            _this.codeText = '发送验证码';
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.popup-phone {
  box-sizing: border-box;
  height: 324px;
  .title {
    text-align: center;
    .fontSize(@TEXT-SIZE-18);
    font-weight: bold;
    margin-bottom: 40px;
  }
  .phone-input {
    padding: 0 15px;
    width: 100%;
    height: 38px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .phone-input:focus {
    border: 1px solid #00bd92;
  }
  .bor {
    margin-top: 20px;
    .codeImgShow {
      width: 120px;
      height: 38px;
      cursor: pointer;
    }
  }
  .top {
    margin-top: 20px;
    .flex-input {
      padding: 0 15px;
      width: 200px;
      height: 38px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .flex-input:focus {
      border: 1px solid #00bd92;
    }
  }
  .dialog-btn {
    margin: 40px auto 0 auto;
    width: 180px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    .fontSize(@TEXT-SIZE-16);
    color: @TEXT-COLOR-FFFFFF;
    background: @TEXT-BG-00BD92;
  }
  .verification {
    .fontSize(@TEXT-SIZE-12);
    color: @TEXT-COLOR-00BD92;
    margin-left: 10px;
    cursor: pointer;
  }
  /deep/.el-dialog {
    border-radius: 10px;
  }
  /deep/.el-dialog__body {
    padding: 10px 60px;
    box-sizing: border-box;
    color: @TEXT-COLOR-363636;
    height: 324px;
    overflow: hidden;
  }
  /deep/.el-dialog__headerbtn /deep/.el-dialog__close:hover {
    color: @TEXT-COLOR-00BD92;
  }
  /deep/.el-dialog__headerbtn /deep/.el-dialog__close {
    color: @TEXT-COLOR-363636;
  }
  /deep/.el-dialog__header {
    font-size: 20px;
    font-weight: bold;
  }
  /deep/.el-dialog__headerbtn {
    font-size: 20px;
    font-weight: bold;
  }
  .binding-success {
    box-sizing: border-box;
    padding-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .bg {
      width: 102px;
      height: 102px;
      background: url("~@/assets/images/personage/p-1394.png") no-repeat 0px 0px;
      margin-bottom: 30px;
    }
    .text {
      .fontSize(@TEXT-SIZE-16);
    }
  }
}
</style>