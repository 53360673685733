<template>
  <div class="addInformation-container">
    <div class="title">{{ title }}</div>
    <div class="addInformation-box flex conet">
      <div class="addInformation-box-title">姓名：</div>
      <input
        class="addInformation-input"
        type="text"
        v-model="name"
        maxlength="20"
        placeholder="请输入姓名"
      />
    </div>
    <div class="addInformation-box flex conet">
      <div class="addInformation-box-title">手机号：</div>
      <div class="rig">
        <input
          class="addInformation-input"
          type="text"
          v-model="phone"
          maxlength="11"
          oninput="value=value.replace(/[^0-9]/g,'')"
          placeholder="请输入手机号"
        />
      </div>
    </div>
    <template v-if="userPhone == null || userPhone == ''">
      <div class="addInformation-box flex conet">
        <div class="addInformation-box-title">图形验证码：</div>
        <div class="rig">
          <input
            class="addInformation-input"
            type="text"
            v-model="codeImg"
            maxlength="4"
            oninput="value=value.replace(/[^0-9A-Za-z]/g,'')"
            placeholder="输入图形验证码"
          />
          <img
            :src="picImg"
            alt=""
            class="codeImgShow"
            @click.stop="getVerifyPic"
          />
          <div class="code" @click="verificationCodeIncident">
            {{ codeText }}
          </div>
        </div>
      </div>
      <div class="addInformation-box flex conet">
        <div class="addInformation-box-title">短信验证码：</div>
        <div class="rig">
          <input
            class="addInformation-input"
            type="text"
            v-model="code"
            oninput="value=value.replace(/[^0-9]/g,'')"
            maxlength="6"
            placeholder="输入短信验证码"
          />
        </div>
      </div>
    </template>
    <template v-if="userPhone != phone && userPhone.length > 0">
      <div class="addInformation-box flex conet">
        <div class="addInformation-box-title">图形验证码：</div>
        <div class="rig">
          <input
            class="addInformation-input"
            type="text"
            v-model="codeImg"
            maxlength="4"
            oninput="value=value.replace(/[^0-9A-Za-z]/g,'')"
            placeholder="输入图形验证码"
          />
          <img
            :src="picImg"
            alt=""
            class="codeImgShow"
            @click.stop="getVerifyPic"
          />
          <div class="code" @click="verificationCodeIncident">
            {{ codeText }}
          </div>
        </div>
      </div>
      <div class="addInformation-box flex conet">
        <div class="addInformation-box-title">短信验证码：</div>
        <div class="rig">
          <input
            class="addInformation-input"
            type="text"
            v-model="code"
            oninput="value=value.replace(/[^0-9]/g,'')"
            maxlength="6"
            placeholder="输入短信验证码"
          />
        </div>
      </div>
    </template>
    <div class="addInformation-box flex conet">
      <div class="addInformation-box-title">地区：</div>
      <div class="rig flex conet">
        <el-select
          placeholder="省"
          v-model="province"
          @change="provinceChange($event, 'province')"
        >
          <el-option
            class="option-text"
            v-for="item in province_list"
            :key="item.id"
            :label="item.regionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          placeholder="市"
          v-model="city"
          :disabled="disabledCity"
          @change="provinceChange($event, 'city')"
        >
          <el-option
            class="option-text"
            v-for="item in city_list"
            :key="item.id"
            :label="item.regionName"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          placeholder="区/县"
          v-model="county"
          :disabled="disabledCounty"
          @change="provinceChange($event, 'area')"
        >
          <el-option
            class="option-text"
            v-for="item in county_list"
            :key="item.id"
            :label="item.regionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="addInformation-box flex conet">
      <div class="addInformation-box-title">工作单位：</div>
      <div class="rig rigSelect">
        <el-select
          :remote-method="remoteMethod"
          remote
          placeholder="选择工作单位"
          filterable
          v-model="schoolName"
          :disabled="disabledLocation"
          @change="provinceChange($event, 'school')"
        >
          <el-option
            class="option-text"
            v-for="item in location_list"
            :key="item.id"
            :label="item.schoolName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <el-button
      :loading="loading"
      class="flex conet j-c addInformation-btn"
      @click="informationRegistrationIncident"
    >
      确认{{ genre ? "添加" : "编辑" }}
    </el-button>
  </div>
</template>
<script>
import Utils from '@/utils';
import { countDown } from '@/utils/commonUtils';
import { mapGetters } from 'vuex';
import { checkPhone, checkName, checkExpression } from '@/utils/verify';
import {
  locationProvince,
  locationCity,
  areaSchool,
  locationArea,
  verifyPic,
  checkVerify,
  smsPhone
} from '@/api/personal';
export default {
  name: 'addInformation',
  props: {
    title: {
      type: String,
      default: '添加用户信息'
    },
    genre: {
      type: Boolean,
      default: true
    },
    objDialog: {
      type: Object
    }
  },
  data() {
    return {
      name: '', // 姓名
      phone: '', // 手机号码
      codeImg: '', // 图形验证码
      code: '', // 短信验证码
      province_list: [], // 省数组
      province: '', // 省
      city_list: [], // 市数组
      disabledCity: true, // 市可否选择
      city: '', // 市
      disabledCounty: true, // 区可否选择
      county_list: [], // 区数组
      county: '', // 区
      disabledLocation: true, // 工作单位可否选择
      location_list: [], // 工作单位数组
      schoolName: '', // 工作单位
      schoolId: '', // 工作单位id
      codeText: '获取验证码', // 验证码文本
      getOnceCodeFlag: true, // 是否可以点击验证码请求
      codekey: '', // 图形验证码key值
      picImg: '', // 图形验证码图片
      getCodeFlag: false, // 图形验证码校验
      courseTeacherInfoId: '', // 编辑时使用的id
      schoolNameQuery: '', // 搜索字段
      locationId: '', // 查询学校id
      isNeed: true, // 是否需要验证码
      loading: false
    };
  },
  watch: {
    name(val) {
      setTimeout(() => {
        if (checkName(val)) {
          this.name = val.substring(0, val.length - 1);
        }
        if (checkExpression(val)) {
          this.name = '';
        }
      }, 100);
    },
    codeImg(val) {
      const _this = this;
      if (val.length > 3) {
        checkVerify({ key: _this.codekey, code: val }).then((res) => {
          if (res.code == 200) {
            if (res.data) {
              _this.getCodeFlag = true;
            } else {
              Utils.msgWarning('图形验证码校验失败');
              _this.getVerifyPic();
              _this.codeImg = '';
            }
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters(['userPhone', 'username'])
  },
  created() {
    this.$nextTick(() => {
      this.name = this.username;
      this.phone = this.userPhone;
      this.init();
    });
  },
  methods: {
    // 搜索远程
    remoteMethod(query) {
      const _this = this;
      if (query !== '') {
        _this.schoolNameQuery = query;
        setTimeout(() => {
          _this.getAreaSchool(_this.locationId);
        }, 200);
      } else {
        _this.schoolNameQuery = '';
        _this.getAreaSchool(_this.locationId);
      }
    },
    init() {
      const _this = this;
      // 获取城市
      locationProvince({}).then((res) => {
        if (res.code == 200) {
          _this.province_list = res.data;
        }
      });
      _this.getVerifyPic();
      if (_this.genre) return;
      const { name, phone, courseTeacherInfoId } = _this.objDialog;
      _this.name = name || '';
      _this.phone = phone || '';
      _this.courseTeacherInfoId = courseTeacherInfoId || '';
    },
    getVerifyPic() {
      const _this = this;
      verifyPic({}).then((res) => {
        if (res.code == 200) {
          _this.picImg = 'data:image/png;base64,' + res.data.pic;
          _this.codekey = res.data.key;
        }
      });
    },
    beforClose() {
      this.$emit('beforClose', {});
    },
    // 确认按钮事件提交
    informationRegistrationIncident() {
      const _this = this;
      if (_this.name == '') {
        Utils.msgWarning('请输入您的姓名');
        return;
      }
      if (!checkPhone(_this.phone)) {
        Utils.msgWarning('手机号码不正确');
        return;
      }
      if (_this.userPhone == null || _this.userPhone == '') {
        _this.isNeed = true;
        if (_this.codeImg == '' && _this.codeImg.length < 3) {
          Utils.msgWarning('图形验证码不正确');
          return;
        }
        if (_this.code == '') {
          Utils.msgWarning('手机验证码不正确');
          return;
        }
      } else {
        _this.isNeed = false;
      }
      if (_this.userPhone != _this.phone && _this.userPhone.length > 0) {
        _this.isNeed = true;
        if (_this.codeImg == '' && _this.codeImg.length < 3) {
          Utils.msgWarning('图形验证码不正确');
          return;
        }
        if (_this.code == '') {
          Utils.msgWarning('手机验证码不正确');
          return;
        }
      } else {
        _this.isNeed = false;
      }
      if (_this.schoolId == '') {
        Utils.msgWarning('请选择您所在城市的工作单位');
        return;
      }
      const addInformationObj = {
        name: _this.name,
        phone: _this.phone,
        schoolId: _this.schoolId
      };
      if (_this.isNeed) {
        addInformationObj.smsCode = _this.code;
      }
      if (!_this.genre) {
        addInformationObj.id = _this.courseTeacherInfoId;
        addInformationObj.isDefault = _this.objDialog.isDefault;
      }
      _this.loading = true;
      _this.$emit('informationRegistrationIncident', {
        obj: addInformationObj,
        isNeed: _this.isNeed
      });
    },
    loadingIncident() {
      this.loading = false;
    },
    // 获取验证码
    verificationCodeIncident() {
      const _this = this;
      if (!checkPhone(_this.phone)) {
        Utils.msgWarning('手机号码不正确');
        return;
      }
      if (!_this.getCodeFlag) {
        Utils.msgWarning('图形验证码不正确');
        return;
      }
      if (_this.getOnceCodeFlag) {
        _this.getOnceCodeFlag = false;
        smsPhone({
          phone: _this.phone,
          key: _this.codekey,
          code: _this.codeImg
        })
          .then((res) => {
            if (res.code == 200) {
              Utils.msgSuccess('发送验证码成功，请注意查看手机短信');
              countDown(60, (time) => {
                _this.codeText = `${time}s`;
                if (time <= 0) {
                  _this.getOnceCodeFlag = true;
                  _this.codeText = '发送验证码';
                }
              });
            } else {
              _this.getOnceCodeFlag = true;
            }
          })
          .catch(() => {
            _this.getOnceCodeFlag = true;
          });
      }
    },
    // 下拉方法
    provinceChange(e, method) {
      const _this = this;
      switch (method) {
        case 'province':
          _this.disabledCity = true;
          _this.disabledCounty = true;
          _this.city = '';
          _this.county = '';
          _this.schoolName = '';
          _this.disabledLocation = true;
          locationCity({ provinceId: e }).then((res) => {
            _this.city_list = res.data;
            _this.disabledCity = false;
          });
          break;
        case 'city':
          _this.disabledCounty = true;
          _this.county = '';
          _this.schoolName = '';
          _this.disabledLocation = true;
          locationArea({ cityId: e }).then((res) => {
            _this.county_list = res.data;
            _this.disabledCounty = false;
          });
          break;
        case 'area':
          _this.disabledLocation = true;
          _this.schoolName = '';
          _this.locationId = e;
          _this.getAreaSchool(e);
          break;
        case 'school':
          _this.schoolId = e;
          break;
        default:
          break;
      }
    },
    // 获取学校信息
    getAreaSchool(locationId) {
      areaSchool({
        locationId: locationId,
        schoolName: this.schoolNameQuery
      }).then((res) => {
        if (res.code == 200) {
          this.disabledLocation = false;
          this.location_list = res.data.list;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.addInformation {
  &-container {
    box-sizing: border-box;
    .title {
      text-align: center;
      .fontSize(@TEXT-SIZE-18);
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
  &-box {
    height: 60px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    &-title {
      width: 100px;
      margin-right: 10px;
      text-align: right;
      .fontSize(@TEXT-SIZE-16);
      font-weight: 400;
    }
    .rig {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      overflow: hidden;
      .codeImgShow {
        height: 35px;
        width: 80px;
        padding-left: 10px;
        cursor: pointer;
        box-sizing: border-box;
      }
      .code {
        width: 100px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background: @TEXT-BG-F4F4F4;
        color: @TEXT-COLOR-888888;
        .fontSize(@TEXT-SIZE-14);
        margin-left: 10px;
        cursor: pointer;
      }
      .site-bg {
        margin-right: 40px;
        box-sizing: border-box;
        padding-left: 30px;
        height: 20px;
        background: url("~@/assets/images/home/unselected.png") no-repeat 0px
          0px;
        line-height: 18px;
        cursor: pointer;
        &.active {
          background: url("~@/assets/images/home/pitch-on.png") no-repeat 0px
            0px;
        }
      }
      .selectBox(100px,38px,10px);
    }
    .rigSelect {
      .selectBox(100%,38px,0px);
    }
  }
  &-input {
    flex: 1;
    height: 38px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 15px;
    border-radius: 20px;
    border: 1px solid @TEXT-BG-E6E6E6;
  }
  &-btn {
    margin: 39px auto 0 auto;
    width: 180px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    .fontSize(@TEXT-SIZE-16);
    color: @TEXT-COLOR-FFFFFF;
    background: @TEXT-BG-00BD92;
  }
}
input:focus {
  border: 1px solid #00cc9d;
}
/deep/.el-button {
  &:active,
  &:focus,
  &:hover {
    border: transparent !important;
  }
}
</style>