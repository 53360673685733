<template>
  <div class="manage">
    <PersonageNav></PersonageNav>
    <PersonageTabs :list="manageList" :scheduleIndex.sync="scheduleIndex">
    </PersonageTabs>
    <div class="container">
      <div class="container-fluid information">
        <template v-if="scheduleIndex == 0">
          <Site></Site>
        </template>
        <template v-else>
          <SignTable></SignTable>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Config from '@/config/index';
import PersonageNav from './../components/personage-nav';
import PersonageTabs from './../components/personage-tabs';
import Site from './components/site';
import SignTable from './components/sign-table';
export default {
  name: 'Manage',
  components: {
    PersonageNav,
    PersonageTabs,
    Site,
    SignTable
  },
  data() {
    return {
      scheduleIndex: 0,
      manageList: Config.MANAGE_LIST
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    scheduleIndex(val) {
      const { type } = this.$route.query;
      this.replaceRouter(val, type);
    }
  },
  methods: {
    init() {
      const { path, type } = this.$route.query;
      if (path == '/courseDetails') {
        this.scheduleIndex = 1;
      } else {
        if (type) {
          this.scheduleIndex = parseInt(type);
        }
      }
    },
    // 路由替换
    replaceRouter(date, parameter) {
      if (parameter == date) {
        return;
      }
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, type: date }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.manage {
  box-sizing: border-box;
  .container-fluid {
    padding: 0;
    overflow: hidden;
  }
  .information {
    min-height: 500px;
  }
}
</style>