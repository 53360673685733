<template>
  <div class="container">
    <div class="container-fluid">
      <div class="flex personage-tabs pr">
        <div
          ref="gatherLi"
          v-for="(itme, index) in list"
          :key="index"
          :class="['bxo', 'flex', 'conet', itme.active]"
          @click="scheduleIncident(itme, index)"
        >
          <div class="img" v-if="itme.img">
            <img :src="itme.img" alt="" />
          </div>
          <span :class="[scheduleIndex == index ? 'active' : '']">{{
            itme.title
          }}</span>
        </div>
        <div
          class="pa"
          :style="{ width: width + 'px', left: width * scheduleIndex + 'px' }"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PersonageTabs',
  props: {
    list: {
      type: Array,
      default: []
    },
    scheduleIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      width: 0
    };
  },
  mounted() {
    this.width = this.$refs.gatherLi[0].clientWidth;
  },
  methods: {
    scheduleIncident(itme, index) {
      this.$emit('update:scheduleIndex', index);
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.personage-tabs {
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  .border-direction(bottom,1px,solid,@TEXT-BG-F4F4F4);
  .bxo {
    flex: 1;
    height: 40px;
    cursor: pointer;
    .border-direction(right,1px,solid,@TEXT-BG-F4F4F4);
    justify-content: center;
    .img {
      margin-right: 15px;
    }
    span {
      .fontSize(@TEXT-SIZE-18);
    }
    .active {
      color: @TEXT-COLOR-00BD92;
      font-weight: 400;
    }
  }
  .bor {
    border-right: none !important;
  }
}
.pr {
  position: relative;
}
.pa {
  position: absolute;
  bottom: 0;
  height: 3px;
  background: @TEXT-BG-00BD92;
  transition: all 0.2s linear;
}
</style>

