export default {
  PERSONAGE_ARR: [
    {
      img: require('@/assets/images/personage/course.png'),
      title: '我的课程表'
    },
    {
      img: require('@/assets/images/personage/period.png'),
      title: '我的学时'
    },
    {
      img: require('@/assets/images/personage/sing-in.png'),
      title: '我的签到'
    },
    {
      img: require('@/assets/images/personage/record.png'),
      title: '学习记录'
    },
    {
      img: require('@/assets/images/personage/collect.png'),
      title: '我的收藏',
      active: 'bor'
    }
  ],
  // 个人信息管理
  MANAGE_LIST: [
    {
      img: '',
      title: '个人信息管理'
    },
    {
      img: '',
      title: '报名信息管理',
      active: 'bor'
    }
  ]
}