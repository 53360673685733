<template>
  <div class="container sign">
    <div class="container-fluid">
      <div class="flex conet j-w sign-table">
        <div class="left">
          温馨提示：设置默认报名信息后，每次报名会默认使用该报名信息。
        </div>
        <div
          class="sign-btn flex conet j-c"
          @click.stop="evaluateIncident($event, 'add')"
          v-if="tableData.length <= 5"
        >
          添加报名信息
        </div>
        <div
          class="sign-btns flex conet j-c"
          @click.stop="evaluateIncident($event, 'full')"
          v-else
        >
          添加报名信息
        </div>
      </div>
      <div v-loading="loading">
        <template v-if="tableData.length > 0">
          <el-table :data="tableData" style="width: 100%" max-height="660">
            <el-table-column prop="name" label="姓名" width="120">
              <template slot-scope="scope">
                <span class="tilte">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="phone"
              label="手机号码"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <span class="text">{{ scope.row.phone | phoneFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column label="地区" align="center" width="280">
              <template slot-scope="scope">
                <span class="text"
                  >{{ scope.row.province }}/{{ scope.row.city }}/{{
                    scope.row.area
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="工作单位" align="center" width="280">
              <template slot-scope="scope">
                <span class="text">{{ scope.row.schoolName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="150">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.stop="evaluateIncident(scope.row, 'delete')"
                >
                  删除
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  @click.stop="evaluateIncident(scope.row, 'compile')"
                >
                  编辑
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="设置默认" align="center" width="150">
              <template slot-scope="scope">
                <div
                  :class="['bgs', scope.row.isDefault ? 'active' : '']"
                  @click.stop="evaluateIncident(scope.row, 'bg')"
                ></div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else>
          <AbsolutelyEmpty
            title="暂无报名信息！"
            :img="signTsleImg"
          ></AbsolutelyEmpty>
        </template>
      </div>
    </div>
    <!-- 报名 -->
    <template v-if="dialogVisible">
      <HaiPopup
        ref="modal"
        :dialogVisible="dialogVisible"
        @beforClose="
          () => {
            dialogVisible = false;
          }
        "
        :objDialog="objDialog"
        :title="title"
        :genre="genre"
        @informationRegistrationIncident="addInformationRegistrationIncident"
      ></HaiPopup>
    </template>
  </div>
</template>
<script>
import Utils from '@/utils';
import { Loading } from 'element-ui';
import HaiPopup from './popup';
import AbsolutelyEmpty from '@/components/AbsolutelyEmpty/index';
import signTsleImg from '@/assets/images/personage/sho-chang.png';
import {
  courseTeacherInfo,
  postCourseTeacherInfo,
  courseTeacherInfoDefault,
  putCourseTeacherInfo,
  deleteCourseTeacherInfo,
  courseTeacherInfoNocode,
  putCourseTeacherInfoNocode
} from '@/api/personal';
import { mapGetters } from 'vuex';
export default {
  name: 'LearnTable',
  components: {
    HaiPopup,
    AbsolutelyEmpty
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      currentTotal: 0,
      tableData: [],
      dialogVisible: false, // 显示弹出窗口
      objDialog: {}, // 编辑传值
      title: '', // 弹出窗口title
      loading: true,
      genre: true, // 编辑 false 添加 true
      signTsleImg: signTsleImg
    };
  },
  filters: {
    phoneFilter(val) {
      return val.substring(0, 3) + '****' + val.substring(7, val.length);
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(['userPhone'])
  },
  methods: {
    init() {
      this.getCourseTeacherInfo(this.pageNum);
    },
    // 查询报名信息列表
    getCourseTeacherInfo(pageNum) {
      const _this = this;
      const jsonData = {
        pageNum: pageNum,
        pageSize: _this.pageSize
      };
      courseTeacherInfo(jsonData).then((res) => {
        if (res.code == 200) {
          const { list, total } = res.data;
          _this.currentTotal = total;
          _this.tableData = list;
          const { path } = _this.$route.query;
          if (path == '/courseDetails' && list.length <= 0) {
            _this.title = '添加报名信息';
            _this.dialogVisible = true;
            _this.genre = true;
          }
          _this.loading = false;
        }
      });
    },
    evaluateIncident(row, method) {
      const _this = this;
      if (!_this.userPhone) {
        _this.$phonePopup((res) => {});
        return;
      }
      switch (method) {
        case 'add':
          _this.title = '添加报名信息';
          _this.dialogVisible = true;
          _this.objDialog = {};
          _this.genre = true;
          break;
        case 'delete':
          _this.$trainPopup({ title: '是否删除该报名信息?' }).then((res) => {
            deleteCourseTeacherInfo({ id: row.courseTeacherInfoId }).then(
              (res) => {
                if (res.code == 200) {
                  this.$loading();
                  setTimeout(() => {
                    this.$loading().close();
                    Utils.msgSuccess('删除成功');
                    this.getCourseTeacherInfo(this.pageNum);
                  }, 1500);
                }
              }
            );
          });
          break;
        case 'compile':
          _this.title = '编辑报名信息';
          _this.objDialog = row;
          _this.dialogVisible = true;
          _this.genre = false;
          break;
        case 'bg':
          if (row.isDefault) {
            Utils.msgSuccess('已经设置了哦~');
          } else {
            _this
              .$trainPopup({
                title: '设置为默认选项?',
                leftBtn: '取消',
                rightBtn: '确认'
              })
              .then((res) => {
                courseTeacherInfoDefault({
                  courseTeacherInfoId: row.courseTeacherInfoId
                }).then((res) => {
                  if (res.code == 200) {
                    this.$loading();
                    setTimeout(() => {
                      this.$loading().close();
                      Utils.msgSuccess('设置默认成功');
                      this.getCourseTeacherInfo(this.pageNum);
                    }, 1500);
                  }
                });
              });
          }
          break;
        case 'full':
          Utils.msgWarning('最多只能保留6个报名信息~');
          break;
        default:
          break;
      }
    },
    // 公共返回方法
    addComm() {
      setTimeout(() => {
        this.dialogVisible = false;
        Utils.msgSuccess('添加成功');
        const { path, id } = this.$route.query;
        if (path == '/courseDetails' && this.tableData.length <= 0) {
          this.$router.replace({
            path: '/courseDetails',
            query: {
              id: id
            }
          });
          return;
        }
        this.getCourseTeacherInfo(this.pageNum);
      }, 300);
    },
    compileComm() {
      setTimeout(() => {
        this.$refs.modal.loadingIncident();
        this.dialogVisible = false;
        Utils.msgSuccess('编辑成功');
        this.getCourseTeacherInfo(this.pageNum);
      }, 300);
    },
    addInformationRegistrationIncident(e) {
      const _this = this;
      if (_this.genre) {
        _this.tableData.length <= 0
          ? (e.obj.isDefault = true)
          : (e.obj.isDefault = false);
        if (e.isNeed) {
          // 有验证码添加
          postCourseTeacherInfo(e.obj).then((res) => {
            if (res.code == 200) {
              _this.addComm();
            }
          });
        } else {
          // 无验证码添加
          courseTeacherInfoNocode(e.obj).then((res) => {
            if (res.code == 200) {
              _this.addComm();
            }
          });
        }
      } else {
        if (e.isNeed) {
          putCourseTeacherInfo(e.obj).then((res) => {
            if (res.code == 200) {
              this.compileComm();
            }
          });
        } else {
          putCourseTeacherInfoNocode(e.obj).then((res) => {
            if (res.code == 200) {
              this.compileComm();
            }
          });
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.sign {
  box-sizing: border-box;
  min-height: 300px;
  margin-bottom: 100px;
  .sign-table {
    padding-top: 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    .left {
      color: #ff5102;
      font-size: 14px;
    }
    .sign-btn {
      width: 148px;
      height: 40px;
      background: #00bd92;
      color: #ffffff;
      border-radius: 20px;
      cursor: pointer;
    }
    .sign-btns {
      width: 148px;
      height: 40px;
      background: #e6e6e6;
      color: #ffffff;
      border-radius: 20px;
      cursor: pointer;
    }
  }
  /deep/.cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bgs {
    width: 40px;
    height: 22px;
    background: url("~@/assets/images/personage/p-1402.png") no-repeat 0px 0px;
    cursor: pointer;
    &.active {
      background: url("~@/assets/images/personage/p-1403.png") no-repeat 0px 0px;
    }
  }
  .tilte {
    color: #363636;
  }
  .text {
    color: #888888;
  }
  .el-table {
    /deep/.el-table__header-wrapper,
    /deep/.el-table__header,
    /deep/.has-gutter {
      background: #f4f4f4 !important;
      /deep/ tr /deep/ th {
        background: #f4f4f4 !important;
        color: #363636 !important;
      }
    }
  }
}
</style>